@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100vh;
  margin: 0;
  background-color: #fadadf;
  color: #ff0066;
  font-family: "Playfair Display", sans-serif;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 1rem;
}

:root {
  --color-brand-primary: #e20f7f;
  --color-brand-secondary: #f4bad5;
}

/* HomeSectionFour.css */

.slider-container {
  position: relative;
  padding-bottom: 40px; /* Adjust this value as needed */
}

.custom-slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.custom-slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.custom-slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.custom-slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #A020F0; /* Adjust this to match your design */
}

.custom-slick-dots li.slick-active button:before {
  opacity: .75;
  color: #A020F0; /* Adjust this to match your design */
}


/* Add these styles to your CSS */
.selected-date {
    background-color: # !important;
    color: white !important;
}

.dot {
    height: 4px;
    width: 4px;
    background-color: #your-brand-primary-color;
    border-radius: 50%;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
}